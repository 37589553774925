




































































































































































































































































import RepairCompleteTableComponent from "@/components/staffManagement/components/repaircompletable/RepairCompleteTable";
export default RepairCompleteTableComponent;

import { Vue, Component, Prop } from 'vue-property-decorator';
import UploadFileComponent from '../components/uploadfile/UploadFileComponent.vue'
import APP_CONST from '@/constants/AppConst';
import { ScreenText } from '@/lang/ScreenText';
import { fetchLMSUrl } from "@/services/dashboard/dashboardService";
import { staffStatus } from "@/services/staff/staffService"
import APP_UTILITIES from "@/utilities/commonFunctions";

import StaffMatchColumnsComponent from '@/components/staffManagement/components/matchcolumns/StaffMatchColumnsComponent.vue';
import RepairCompleteTableComponent from '../components/repaircompletable/RepairCompleteTable.vue';
@Component({
    components: {
        'upload-file': UploadFileComponent,
        'match-columns': StaffMatchColumnsComponent,
        'repair-table': RepairCompleteTableComponent
    }
})
export default class StaffRoster extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    stepper: {title: string, component: string, class: string}[] = JSON.parse(JSON.stringify(APP_CONST.STAFF_STEPPER));
    stepId: number = 0;
    public linkUrlId : number = APP_CONST.FOUR;
    component = "";

    @Prop()
    userDetails!: {}

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    activeStepper(component: string) {
        const index = this.stepper.findIndex((step) => step.component === component);
        if (index >= 0) {
            this.scrollToTop();
            this.loopThroughStepper(index);
        }
    }

    disableStepper(step: { title: string, component: string, class: string }) {
        if (step.component !== this.component) {
            const stepId = this.stepper.findIndex((stepper) => stepper.component === step.component);
            this.loopThroughStepper(stepId);
            this.component = step.component;
            this.judgeStepId();
            this.scrollToTop();
        }
    }

    shiftScreen(e: string) {
        this.activeStepper(e);
        this.component = e;
        this.judgeStepId();
    }

    loopThroughStepper(stepId: number) {
        this.stepper.forEach((step, index) => {
            if(stepId === 3) {
                step.class = (index < stepId) ? 'complete-with-disable' : APP_CONST.ACTIVE_CLASS;
            } else {
                if (index < stepId) {
                    step.class = APP_CONST.COMPLETE_CLASS;
                } else if (index === stepId) {
                    step.class = APP_CONST.ACTIVE_CLASS;
                } else {
                    step.class = '';
                }
            }
        });
    }

    judgeStepId() {
        if(this.component === 'upload-file') {
            this.stepId = 0;
        }
        if(this.component === 'repair-table') {
            this.stepId = 2;
        }
    }

    scrollToTop() {
        window.scrollTo(APP_CONST.ZERO, APP_CONST.ZERO);
    }

    navigateLMSUrl(){
        fetchLMSUrl(this.linkUrlId).then((res: any) => {
            if(res.status === APP_CONST.RESPONSE_200) {
                    window.open(res.data,'_blank');
            }
        })
    }

    checkCurrentStep() {
        const { siteId,programId } = APP_UTILITIES.coreids();
        let component:string = "";
        let accountId = APP_UTILITIES.getCookie('accountId');
        staffStatus(accountId).then((response : any) => {
            if(response.data && response.data.step3) {
                component = 'repair-table';
            } else if(response.data && response.data.step2) {
                component = 'match-columns';
            } else {
                component = 'upload-file';
            }
            this.shiftScreen(component);
            
        }).catch(()=>{})
    }

    mounted() {
        this.checkCurrentStep()
    }
}
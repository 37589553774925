



















































































import { UnresolvedAlertComponent } from "@/popupcomponents/unresolvedalertcomponent/UnresolvedAlertComponent";
export default UnresolvedAlertComponent;
